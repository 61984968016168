<template>
  <div class="audits">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <DataTable
      class="p-datatable-sm"
      :rowHover="true"
      :value="audits"
      :loading="isLoading"
      editMode="cell"
      :lazy="true"
      filterDisplay="row"
      :filters.sync="tableState.filters"
      stateStorage="local"
      :stateKey="tableState.filterName"
      :totalRecords="getAuditCount"
      :paginator="true"
      :rows.sync="tableState.pagination.pageSize"
      :first="pageOffset"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :currentPageReportTemplate="
        $t('show') +
          ' {first} ' +
          $t('to') +
          ' {last} ' +
          $t('of') +
          ' {totalRecords} ' +
          $t('records')
      "
      :rowsPerPageOptions="[10, 20, 50, 100]"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      :expandedRows.sync="expandedRows"
      @row-expand="onRowExpand"
      @row-collapse="onRowCollapse"
      @cell-edit-complete="onCellEditComplete"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
      @state-restore="onStateRestore($event)"
      responsiveLayout="scroll"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <div class="mt-2">
              {{ getAuditCount }}
              {{ $tc('audit_pluralized', getAuditCount) }}
            </div>
          </div>
          <div class="table-header-right">
            <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click.prevent="onRemoveFilters()">
              <i class="fa fa-remove mr-2"></i>
              {{ $t('remove_filters') }}
            </button>
            <div class="button-right">
              <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="expandAll">
                Alle öffnen
              </Button>
              <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="collapseAll">
                Alle schliessen
              </button>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>

      <Column :expander="true" :headerStyle="'width: 30px'" />

      <Column
        field="createdAt"
        :header="$t('date')"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          {{ $datetime(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column
        field="action"
        :header="$t('action')"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          {{ slotProps.data.action }}
        </template>

        <!-- <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template> -->
      </Column>

      <Column field="user" :header="$t('users')" headerClass="center" className="center" sortable>
        <template #body="slotProps">
          {{ slotProps.data.user.firstname }} {{ slotProps.data.user.lastname }} ({{
            slotProps.data.user.email
          }})
        </template>

        <!-- <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template> -->
      </Column>

      <template #expansion="slotProps">
        <!-- <div class="expansion grid"> -->
        <div v-if="slotProps.data.payload">
          <pre>{{ slotProps.data.payload }}</pre>
        </div>
        <div v-else><pre>N/A</pre></div>
        <!-- </div> -->
      </template>
    </DataTable>
  </div>
</template>
>
<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Breadcrumbs from '@/components/Breadcrumbs';

export default {
  components: {
    Breadcrumbs,
    DataTable,
    Column,
  },
  data() {
    return {
      expandedRows: [],

      tableState: {
        filterName: 'audits-table-filters',
        customFilterName: 'audits-custom-table-filters',
        filters: this.defaultFilters,
        customFilters: this.defaultCustomFilters,
        pagination: {
          skip: 0,
          pageSize: 10,
        },
        sorting: { sortField: 'createdAt', sortOrder: -1 },
        sortOrder: -1,
      },
    };
  },
  computed: {
    // ...mapGetters('auth', ['isAdmin', 'getCurrentUser']),
    ...mapGetters(['isLoading', 'getAudits', 'getAuditCount']),

    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('audit_log') }];
    },
    audits() {
      return this.getAudits;
    },
    pageOffset() {
      return this.tableState.pagination.page * this.tableState.pagination.pageSize;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
  },
  methods: {
    ...mapActions(['fetchAudits']),

    /**
     * Load remote table data
     */
    async loadTableData() {
      await this.fetchAudits({
        pagination: this.tableState.pagination,
        sorting: this.tableState.sorting,
        filters: { ...this.tableState.filters },
      });
    },

    onRowExpand(event) {},
    onRowCollapse(event) {},
    expandAll() {
      this.expandedRows = this.audits.filter((d) => d._id);
    },
    collapseAll() {
      this.expandedRows = null;
    },

    /**
     * send data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, value, field } = event;
      console.log('onCellEditComplete():', event);
    },

    /**
     * Load more results from server or cache on pagination click
     */
    async onPage(event) {
      this.tableState.pagination = { skip: event.page, pageSize: event.rows };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on sort
     */
    async onSort(event) {
      this.tableState.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },

    /**
     * Load results from server/cache on filter
     */
    async onFilter(event) {
      this.loadTableData();
    },

    /**
     *
     * @param {*} event
     */
    async onRemoveFilters(event) {
      this.tableState.sorting = this.defaultSorting;
      this.tableState.pagination = this.defaultPagination;
      this.tableState.filters = this.defaultFilters;
      localStorage.removeItem(this.tableState.filterName);
      localStorage.removeItem(this.tableState.customFilterName);
      await this.loadTableData();
    },

    /**
     * Trigger when state is loaded from local storage
     */
    async onStateRestore(event) {
      this.setupFilters();
      if (this.tableState) {
        this.tableState.sorting = {
          sortField: event.sortField ? event.sortField : this.defaultSorting.sortField,
          sortOrder: event.sortOrder ? event.sortOrder : this.defaultSorting.sortOrder,
        };
        this.tableState.pagination = {
          skip: this.defaultPagination.skip,
          pageSize: event.rows ? event.rows : this.defaultPagination.pageSize,
        };
      }
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName));
      this.tableState.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
    },

    setupFilters() {
      this.tableState.filters = this.tableState.filters
        ? this.tableState.filters
        : this.defaultFilters;
      this.tableState.customFilters = this.tableState.customFilters
        ? this.tableState.customFilters
        : this.defaultCustomFilters;
      this.tableState.sorting = this.tableState.sorting
        ? this.tableState.sorting
        : this.defaultSorting;
      this.tableState.pagination = this.tableState.pagination
        ? this.tableState.pagination
        : this.defaultPagination;
    },
  },
  async mounted() {
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
::v-deep .center .p-column-header-content {
  justify-content: center;
}

::v-deep .p-datatable .p-datatable-tbody td {
  text-align: left;
}
::v-deep .p-datatable .p-datatable-tbody td.center {
  text-align: center;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new {
  background: $light-green;
}
::v-deep .p-datatable .p-datatable-tbody > tr.new:hover {
  background: $green;
}

::v-deep .p-datatable .p-datatable-tbody > tr.monday {
  background: rgba(233, 207, 179, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.monday:hover {
  background: rgba(233, 207, 179, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.tuesday {
  background: rgba(217, 188, 130, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.tuesday:hover {
  background: rgba(217, 188, 130, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.wednesday {
  background: rgba(201, 176, 166, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.wednesday:hover {
  background: rgba(201, 176, 166, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.thursday {
  background: rgba(217, 227, 224, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.thursday:hover {
  background: rgba(217, 227, 224, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.friday {
  background: rgba(210, 208, 157, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.friday:hover {
  background: rgba(210, 208, 157, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.saturday {
  background: rgba(205, 215, 193, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.saturday:hover {
  background: rgba(205, 215, 193, 1);
}

::v-deep .p-datatable .p-datatable-tbody > tr.sunday {
  background: rgba(228, 233, 222, 0.7);
}
::v-deep .p-datatable .p-datatable-tbody > tr.sunday:hover {
  background: rgba(228, 233, 222, 1);
}

.multiselect-project {
  max-width: 500px;
  min-width: 350px;
  display: inline-block;
}
.multiselect-itemStorages,
.multiselect-employees {
  min-width: 180px;
  display: inline-block;
}

::v-deep .multiselect__tags {
  border: 0;
  border-radius: 0;
}

::v-deep .multiselect-employees .multiselect__input {
  padding-top: 1em;
}

/** Fix Dropdown overlay */
.p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 37rem;
}

::v-deep .p-datepicker-title {
  display: flex;
}

::v-deep .btn-icon > button {
  padding: 0;
}

.btn-xs {
  border-radius: 0;
}

::v-deep .p-column-filter-clear-button {
  display: none;
}
::v-deep .p-column-filter-menu-button {
  display: none;
}
.expansion {
  text-align: left;
  margin-left: 1.5em;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1em;
}
.grid > div {
  padding: 1em 1em;
  border: 2px;
  border: 1px solid $gray-300;
  background: white;
}
.flex-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: top;
  width: 100%;
  gap: 0.3em;
  margin-bottom: 0.2em;
}

.flex-row > div {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  overflow-wrap: break-word;
  word-break: break-word;
}

::v-deep .p-dialog {
  min-width: 500px;
}

/** Fix Dropdown overlay */
::v-deep .p-datatable-responsive-scroll > .p-datatable-wrapper {
  min-height: 75vh;
}
</style>
